import Slider from "react-slick";
import "./desktop.scss";
import SlideUpOnScroll from "components/SlideUpOnScroll";

const Introduction = () => {
  const settings = {
    centerMode: true,
    infinite: true,
    centerPadding: "10px",
    slidesToShow: 2.65,
    speed: 500,
    dots: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  const imageData: string[] = [
    "/images/introduction/image1.png",
    "/images/introduction/image2.png",
    "/images/introduction/image3.png",
    "/images/introduction/image4.png",
  ];

  const listFeatures = [
    {
      id: 1,
      title: "Student Management",
      description:
        "Effortlessly track student progress, manage enrollments, and access detailed profiles.",
      icon: "/svgs/introduction/student.svg",
      subTitle: "Advanced Student Management",
      subDescription:
        "Take control of your student data like never before. Our intuitive interface allows you to:",
      subList: [
        "Track individual progress with customizable milestones",
        "Manage enrollments, waitlists, and class transfers effortlessly",
        "Access comprehensive student profiles, including medical information and emergency contacts",
        "Set up automated skill assessments and level advancements",
        "Generate personalized progress reports for parents",
      ],
    },
    {
      id: 2,
      title: "Class Scheduling",
      description:
        "Optimize your class timetables with intelligent scheduling and resource allocation.",
      icon: "/svgs/introduction/class.svg",
      subTitle: "Intelligent Class Scheduling",
      subDescription:
        "Optimize your swim school's operations with our smart scheduling system:",
      subList: [
        "Automatically create balanced class schedules based on instructor availability and pool capacity",
        "Implement recurring classes and manage exceptions with ease",
        "Handle make-up classes and reschedules effortlessly",
        "View color-coded schedules for quick insights into class types and occupancy",
        "Receive alerts for potential scheduling conflicts or overbooked resources",
      ],
    },
    {
      id: 3,
      title: "Instructor Management",
      description:
        "Assign instructors, manage qualifications, and track performance seamlessly.",
      icon: "/svgs/introduction/instructor.svg",
      subTitle: "Comprehensive Instructor Management",
      subDescription: "Empower your teaching and streamline your HR processes:",
      subList: [
        "Maintain detailed instructor profiles, including certifications and specialties",
        "Automatically track work hours and generate payroll reports",
        "Implement a digital clock-in/out system for accurate time tracking",
        "Assign instructors to classes based on their qualifications and availability",
        "Conduct and store performance evaluations digitally",
      ],
    },
    {
      id: 4,
      title: "Financial Oversight",
      description:
        "Gain real-time insights into your financials, from class revenues to overall profitability.",
      icon: "/svgs/introduction/financial.svg",
      subTitle: "Real-Time Financial Oversight",
      subDescription:
        "Gain a crystal-clear view of your swim school's financial health:",
      subList: [
        "Access real-time revenue reports, broken down by class, program, or location",
        "Track expenses and generate profit/loss statements effortlessly",
        "Manage billing cycles and automate payment reminders",
        "Set and monitor financial goals with customizable dashboards",
      ],
    },

    {
      id: 5,
      title: "Multi-Location Support",
      description:
        "Manage multiple locations from a single, unified interface.",
      icon: "/svgs/introduction/location.svg",
      subTitle: "Seamless Multi-Location Management",
      subDescription:
        "Expand your swim school empire without drowning in complexity:",
      subList: [
        "Manage multiple locations from a single, unified dashboard",
        "Implement location-specific pricing, schedules, and policies",
        "Compare performance across different sites with standardized reporting",
        "Easily transfer students or instructors between locations",
        "Maintain consistent branding and communication across all sites",
      ],
    },
    {
      id: 6,
      title: "Communication Hub",
      description:
        "Streamline communication with staff, instructors, and parents all in one place.",
      icon: "/svgs/introduction/communication.svg",
      subTitle: "Streamlined Communication Hub",
      subDescription: "Enhance connectivity across your swim school community:",
      subList: [
        "Centralize all communications in one user-friendly platform",
        "Send automated and manual notifications via SMS, email, and browser to keep everyone informed",
        "Enable secure messaging between instructors and parents",
        "Manage important updates about class schedules, events, and facility information",
        "Track communication history for improved accountability and customer service",
      ],
    },
    {
      id: 7,
      title: "Resource Allocation",
      description:
        "Efficiently manage pool space, equipment, and other resources across your school.",
      icon: "/svgs/introduction/resource.svg",
      subTitle: "Efficient Resource Allocation",
      subDescription: "Optimize your swim school's assets and operations:",
      subList: [
        "Manage multiple swim school locations, each with its own set of schedules, programs, and staff",
        "Implement a centralized calendar system for the entire school's schedule, including classes, events, and holidays",
        "Set up automated alerts for maintenance needs and low inventory",
        "Allocate pool space and resources across all programs and classes",
      ],
    },
    {
      id: 8,
      title: "Reporting",
      description:
        "Generate insightful reports tailored to your specific needs and KPIs",
      icon: "/svgs/introduction/customize.svg",
      subTitle: "Reporting and Analytics",
      subDescription: "Leverage data to drive your swim school's success:",
      subList: [
        "Access real-time dashboards for various aspects of school operations",
        "Generate comprehensive reports on enrollments, finances, and attendance",
        "Track all user activities within the system for accountability and audit purposes",
        "Analyze trends to inform strategic decisions and improve operations",
      ],
    },
  ];
  return (
    <>
      <div className="FeatureStaffPortal-introduction">
        <SlideUpOnScroll>
          <div className="FeatureStaffPortal-introduction--header">
            <div className="FeatureStaffPortal-introduction--header--description">
              Dive into effortless swim school management with Swim Sync's
              comprehensive portal. Designed to be your all-in-one command
              center, our Portal empowers you to oversee every aspect of your
              swim school operations with ease and precision.
            </div>
          </div>
        </SlideUpOnScroll>
        <SlideUpOnScroll>
          <div className="FeatureStaffPortal-introduction--slider-wrapper">
            <Slider {...settings}>
              {imageData.map((item, key) => (
                <div
                  key={key}
                  className="FeatureStaffPortal-introduction--slider-wrapper--image"
                >
                  <img src={item} alt="" />
                </div>
              ))}
            </Slider>
          </div>
        </SlideUpOnScroll>
      </div>
      <div className="FeatureStaffPortal-introduction--featuresContainer">
        <div className="FeatureStaffPortal-introduction--featuresWrapper">
          {listFeatures.map((item, key) => (
            <div
              key={key}
              className="FeatureStaffPortal-introduction--featuresItem"
            >
              <div className="FeatureStaffPortal-introduction--featuresItemMain">
                <div className="FeatureStaffPortal-introduction--featuresItemLeft">
                  <img
                    src={item.icon}
                    alt=""
                    className="FeatureStaffPortal-introduction--featuresItemLeft--icon"
                  />
                  <div className="FeatureStaffPortal-introduction--featuresItemLeft--title">
                    {item.title}
                  </div>
                  <div className="FeatureStaffPortal-introduction--featuresItemLeft--description">
                    {item.description}
                  </div>
                </div>
                <div className="FeatureStaffPortal-introduction--featuresItemRight">
                  <p className="FeatureStaffPortal-introduction--featuresItemRight--subTitle">
                    {item.subTitle}
                  </p>
                  <p className="FeatureStaffPortal-introduction--featuresItemRight--subDescription">
                    {item.subDescription}
                  </p>
                  <ul>
                    {item.subList?.map((li, liIndex) => {
                      return <li key={liIndex}>{li}</li>;
                    })}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default Introduction;
