import AppModal from "components/AppModal";
import { HiXMark } from "react-icons/hi2";
import "./desktop.scss";

interface Props {
  open: boolean;
  onClose: () => void;
}
const LoginModal: React.FC<Props> = ({ open, onClose }) => {
  return (
    <AppModal open={open} onClose={onClose}>
      <div className="loginModal">
        <div className="loginModal-header">
          <div className="loginModal-header__title">Sign In</div>
          <div className="loginModal-header__close" onClick={onClose}>
            <HiXMark size={24} />
          </div>
        </div>
        <div className="loginModal-content">
          <a
            href="https://admin-uat.swimsync.app/"
            className="loginModal-content-item"
          >
            <img src="/images/login-SP.png" alt="" />
            <div className="title">
              Login to <br /> <strong>Command Center</strong>
            </div>
            <div className="description">(Owner, Staff, or Instructor)</div>
          </a>
          <a
            href="https://uat.swimsync.app/"
            className="loginModal-content-item"
          >
            <img src="/images/login-MP.png" alt="" />
            <div className="title">
              Login to <br />
              <strong>Swim Portal</strong>
            </div>
            <div className="description">(Parents or Guardians)</div>
          </a>
        </div>
      </div>
    </AppModal>
  );
};

export default LoginModal;
