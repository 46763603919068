import "./desktop.scss";

import { useState, memo, Fragment } from "react";
import AppInput from "components/AppInput";
import AppCheckbox from "components/AppCheckbox";
import CryptoJS from "crypto-js";

type FormData = {
  fullName: string;
  mobileNumber: string;
  email: string;
  swimmingSchoolName: string;
  numberOfStudents: number;
  challenges: string[];
  interestedFeatures: string[];
  isUsingManagementSystem: boolean;
  expectedImplementationDates: string;
  sources: string;
  customSource?: string;
  customChallenge?: string;
  customInterestedFeature?: string;
};

const MyForm: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    fullName: "",
    mobileNumber: "",
    email: "",
    swimmingSchoolName: "",
    numberOfStudents: 0,
    challenges: [],
    interestedFeatures: [],
    isUsingManagementSystem: false,
    expectedImplementationDates: "",
    sources: "",
    customSource: "",
    customChallenge: "",
    customInterestedFeature: "",
  });

  const questions: {
    question: string;
    options: (string | { label: string; value: boolean })[];
    key: keyof FormData;
    isBoolean?: boolean;
  }[] = [
    {
      question:
        "What are your main challenges with managing your swimming school?",
      options: [
        "scheduling_classes",
        "managing_staff",
        "tracking_student_progress",
        "handling_payments",
        "other",
      ],
      key: "challenges",
    },
    {
      question: "What features are you most interested in?",
      options: [
        "class_management",
        "staff_management",
        "payment_processing",
        "parent_guardian_communication",
        "student_progress_tracking",
        "reporting_analytics",
        "other",
      ],
      key: "interestedFeatures",
    },
    {
      question:
        "Do you currently use any software to manage your swimming school?",
      options: [
        { label: "Yes", value: true },
        { label: "No", value: false },
      ],
      key: "isUsingManagementSystem",
      isBoolean: true,
    },
    {
      question: "How soon are you looking to implement a new system?",
      options: [
        "immediately",
        "within_next_month",
        "next_3_6_months",
        "just_exploring",
      ],
      key: "expectedImplementationDates",
    },
    {
      question: "How did you hear about Swim Sync?",
      options: ["referral", "search_engine", "social_media", "other"],
      key: "sources",
    },
  ];

  // Helper function to capitalize the first letter of a word
  const capitalizeFirstLetter = (word: string) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  const formatLabel = (label: string) => {
    if (label === "other") {
      return "Other (please specify)";
    }

    if (label === "parent_guardian_communication") {
      return "Parent/guardian communication";
    }

    const words = label.replace(/_/g, " ").toLowerCase().split(" ");

    return words
      .map((word, index) => (index === 0 ? capitalizeFirstLetter(word) : word))
      .join(" ");
  };

  const handleOtherChange = (field: keyof FormData, value: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleCheckboxChange = (
    field: keyof FormData,
    value: string | boolean,
    isChecked: boolean
  ) => {
    setFormData((prevData) => {
      if (field === "isUsingManagementSystem") {
        return {
          ...prevData,
          [field]: value as boolean,
        };
      } else {
        let customPrevData = prevData;

        if (value === "other") {
          if (field === "challenges") {
            customPrevData = { ...customPrevData, customChallenge: "" };
          }
          if (field === "sources") {
            customPrevData = { ...customPrevData, customSource: "" };
          }
          if (field === "interestedFeatures") {
            customPrevData = { ...customPrevData, customInterestedFeature: "" };
          }
        }

        return {
          ...customPrevData,
          [field]: isChecked
            ? [value]
            : (prevData[field] as string[]).filter((item) => item !== value), // Remove the value
        };
      }
    });
  };

  const handleInputChange = (
    name: keyof FormData,
    value: string | boolean | number
  ) => {
    if (name === "numberOfStudents") {
      value = Number(value);
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const generateHmacHash = (email: string, timestamp: number) => {
    const secretKey = "swimsynlandingpage";
    const message = `${email}:${timestamp}`;
    const hmac = CryptoJS.HmacSHA256(message, secretKey);
    return hmac.toString(CryptoJS.enc.Hex);
  };

  const validateEmail = (email: string): boolean => {
    // Regular expression to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const [emailError, setEmailError] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const formatFieldName = (field: string): string => {
    return field
      .replace(/([A-Z])/g, " $1")
      .trim()
      .replace(/^./, (match) => match.toUpperCase());
  };

  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);

  const handleSubmit = async () => {
    if (disableSubmit) return;

    const requiredFields: (keyof FormData)[] = [
      "fullName",
      "mobileNumber",
      "email",
      "swimmingSchoolName",
      "sources",
      "challenges",
      "expectedImplementationDates",
      "interestedFeatures",
    ];

    const missingFields: string[] = [];

    // Validate required fields
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        missingFields.push(formatFieldName(field));
      }
    });

    if (formData.sources === "other" && !formData.customSource) {
      missingFields.push(formatFieldName("source"));
    }
    if (
      !formData.challenges?.length ||
      (formData.challenges?.[0] === "other" && !formData.customChallenge)
    ) {
      missingFields.push(formatFieldName("challenges"));
    }
    if (
      !formData.interestedFeatures?.length ||
      (formData.interestedFeatures?.[0] === "other" &&
        !formData.customInterestedFeature)
    ) {
      missingFields.push(formatFieldName("interestedFeatures"));
    }

    // If any required field is missing, show the error messages
    if (missingFields.length > 0) {
      const errorMessage = `Please fill in the following fields: ${missingFields.join(
        ", "
      )}.`;
      setErrorMessages([errorMessage]);
      return;
    }

    setErrorMessages([]);

    if (!validateEmail(formData.email)) {
      setEmailError(true);
      return;
    }
    setEmailError(false);

    const timestamp = Date.now();
    const hash = generateHmacHash(formData.email, timestamp);

    try {
      const response = await fetch(
        "https://swim-sync-api.advancer.asia/api/v1/landing-pages/interested-submission",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            hash,
            timestamp,
            name: formData.fullName,
            phoneNumber: formData.mobileNumber,
            brandName: formData.swimmingSchoolName,
            totalStudent: formData.numberOfStudents,
            email: formData.email,
            challenges: formData.challenges,
            interestedFeatures: formData.interestedFeatures,
            isUsingManagementSystem: formData.isUsingManagementSystem,
            expectedImplementationDates: formData.expectedImplementationDates,
            sources: formData.sources,
            customSource: formData.customSource,
            customChallenge: formData.customChallenge,
            customInterestedFeature: formData.customInterestedFeature,
          }),
        }
      );

      if (response.ok) {
        await response.json();
        setSuccessMessage("Your submission was successful!");
        setErrorMessages([]);

        setDisableSubmit(true);
      } else {
        const error = await response.json();
        console.error("Submission failed:", error);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div className="interested_form">
      <div className="inputList">
        <AppInput
          name="fullName"
          label="Full Name"
          onChange={(e) => handleInputChange("fullName", e.target.value)}
          required
        />
        <AppInput
          name="mobileNumber"
          label="Mobile Number"
          onChange={(e) => handleInputChange("mobileNumber", e.target.value)}
          required
        />
        <AppInput
          name="email"
          label="Email"
          onChange={(e) => handleInputChange("email", e.target.value)}
          required
        />
        <AppInput
          name="swimmingSchoolName"
          label="Swimming School Name"
          onChange={(e) =>
            handleInputChange("swimmingSchoolName", e.target.value)
          }
          required
        />
        <AppInput
          name="numberOfStudents"
          label="Number of Students"
          type="number"
          onChange={(e) =>
            handleInputChange("numberOfStudents", e.target.value)
          }
        />

        <div className="checkboxesList">
          {questions.map(({ question, options, key }, index) => (
            <div key={key}>
              <div className="quest">
                {index + 1}. {question}
              </div>

              <div className="options">
                {options.map((option, i) => {
                  const isStringOption = typeof option === "string";
                  const optionValue = isStringOption ? option : option.value;

                  // Determine if the checkbox should be checked
                  const isChecked =
                    key === "isUsingManagementSystem"
                      ? formData[key] === (optionValue as boolean) // Check for boolean field
                      : Array.isArray(formData[key]) &&
                        (formData[key] as string[]).includes(option as string); // Check for multi-choice

                  return (
                    <Fragment key={i}>
                      <AppCheckbox
                        key={isStringOption ? option : option.label}
                        label={formatLabel(
                          isStringOption ? option : option.label
                        )}
                        checked={isChecked}
                        onChange={(isChecked) =>
                          handleCheckboxChange(key, optionValue, isChecked)
                        }
                      />

                      {isChecked && optionValue === "other" && (
                        <AppInput
                          name={`custom${
                            key.charAt(0).toUpperCase() + key.slice(1)
                          }`}
                          onChange={(e) =>
                            handleOtherChange(
                              key === "challenges"
                                ? "customChallenge"
                                : key === "interestedFeatures"
                                ? "customInterestedFeature"
                                : "customSource",
                              e.target.value
                            )
                          }
                          required
                        />
                      )}
                    </Fragment>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </div>

      {errorMessages.length > 0 && (
        <div className="error-messages">
          {errorMessages.map((msg, index) => (
            <p key={index} className="error">
              {msg}
            </p>
          ))}
        </div>
      )}

      {emailError && (
        <div className="error-messages">
          <p className="error">Incorrect email format</p>
        </div>
      )}

      {successMessage && (
        <div className="success-message">
          <p>{successMessage}</p>
        </div>
      )}

      <button
        type="button"
        onClick={handleSubmit}
        className={disableSubmit ? "btnConfirm btnDisabled" : "btnConfirm"}>
        Confirm
      </button>
    </div>
  );
};

export default memo(MyForm);
