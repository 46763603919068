import Header from "components/header/Header";
import BookDemo from "./components/BookDemo";
import SocialProof from "./components/SocialProof";
import KeyFeatures from "./components/KeyFeatures";
import HowItWorks from "./components/HowItWorks";
import Testimonials from "components/Testimonials";
import DiveIntoSwimSync from "./components/DiveIntoSwimSync";
import FAQs from "./components/FAQs";
import Pricing from "./components/Pricing";
import News from "./components/News";
import Footer from "components/footer";
// import Slider from "react-slick";

const Home = () => {
  // eslint-disable-next-line
  const settings = {
    centerMode: true,
    infinite: true,
    centerPadding: "10px",
    slidesToShow: 7,
    speed: 500,
    dots: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  // eslint-disable-next-line
  const communication = [
    {
      id: 1,
      image: "/images/social/image1.png",
    },
    {
      id: 2,
      image: "/images/social/image2.png",
    },
    {
      id: 3,
      image: "/images/social/image3.png",
    },
    {
      id: 4,
      image: "/images/social/image4.png",
    },
    {
      id: 5,
      image: "/images/social/image5.png",
    },
    {
      id: 6,
      image: "/images/social/image6.png",
    },
    {
      id: 7,
      image: "/images/social/image7.png",
    },
    {
      id: 8,
      image: "/images/social/image8.png",
    },
    {
      id: 9,
      image: "/images/social/image9.png",
    },
  ];
  return (
    <div style={{ overflow: "hidden" }}>
      <div className="app__wrapper">
        <img
          src="/images/header/background-image.png"
          alt=""
          className="app__wrapper--image"
        />
        <Header />
        <BookDemo />
      </div>
      <SocialProof />
      <KeyFeatures />
      <HowItWorks />
      <Testimonials />
      <DiveIntoSwimSync />
      <Pricing />

      {/* <div className="social-proof__communication">
        <div className="social-proof__communication__title">
          Seamlessly Integrates with Popular Payment Gateways and Communication
          Tools
        </div>
        <div className="social-proof__communication__wrapper">
          <Slider {...settings}>
            {communication.map((item, key) => (
              <div
                className="social-proof__communication__wrapper__item"
                key={key}
              >
                <img src={item?.image} alt={item?.image} />
              </div>
            ))}
          </Slider>
        </div>
      </div> */}
      <FAQs />
      <News />
      <Footer />
    </div>
  );
};

export default Home;
