import React, { useState } from "react";

import "./desktop.scss";
import SlideDownOnScroll from "components/SlideDownOnScroll";
import BackgroundAnimation from "./BackgroundAnimation";
import AppModal from "components/AppModal";
import { HiXMark } from "react-icons/hi2";

type DescriptionData = {
  key: string;
  value: string;
};
type LearnMoreData = {
  id: number;
  title: string;
  header: string;
  dataList: DescriptionData[];
  footer: string;
};
const KeyFeatures = () => {
  const [itemSelected, setItemSelected] = useState<LearnMoreData>();
  const [openModal, setOpenModal] = useState(false);
  const keyFeatures = [
    {
      id: 1,
      image: "/images/keyFeatures/image1.png",
      title: "Command Central",
      subTitle: "Your All-in-One Staff Portal",
      bgColor1: "blue",
      bgColor2: "pink",
      bgWidth1: "50%",
      bgWidth2: "50%",
      x1: "10%",
      x2: "-20%",
      y1: "-10%",
      y2: "20%",
      description:
        "Imagine a world where every aspect of your swim school is at your fingertips. Our comprehensive Staff Portal turns this dream into reality. From student progress tracking to multi-location management, effortlessly orchestrate your entire operation. Watch your efficiency soar as you navigate through intuitive interfaces designed for busy swim school professionals.",
    },
    {
      id: 2,
      image: "/images/keyFeatures/image2.png",
      title: "Grow Your School",
      subTitle: "Enrollment Made Splash-tastic",
      bgColor1: "green",
      bgColor2: "yellow",
      bgWidth1: "40%",
      bgWidth2: "50%",
      x1: "20%",
      x2: "-20%",
      y1: "20%",
      y2: "-10%",
      description:
        "Say goodbye to paperwork and hello to growth. Our seamless online joining experience turns curious visitors into enrolled students faster than a champion's butterfly stroke. With an intuitive sign-up process, automatic payment handling, and instant class placement, you'll wonder how you ever managed without it. Watch your enrollment numbers rise as smoothly as a perfect backstroke.",
    },
    {
      id: 3,
      image: "/images/keyFeatures/image3.png",
      title: "Empower Parents",
      subTitle: "A Portal That Makes Waves",
      bgColor1: "red",
      bgColor2: "purple",
      bgWidth1: "40%",
      bgWidth2: "45%",
      x1: "15%",
      x2: "-25%",
      y1: "50%",
      y2: "-20%",
      description:
        "Give parents the power to stay connected with their child's aquatic journey. Our Member Portal isn't just user-friendly; it's a game-changer. Parents can manage enrollments, track progress, and communicate with instructors - all with a few taps. It's like having a virtual swim coach in their pocket, keeping them engaged and informed every stroke of the way.",
    },
    {
      id: 4,
      image: "/images/keyFeatures/image4.png",
      title: "Dive into Data",
      subTitle: "Analytics That Make a Splash",
      bgColor1: "orange",
      bgColor2: "blue",
      bgWidth1: "20%",
      bgWidth2: "30%",
      x1: "5%",
      x2: "-10%",
      y1: "14%",
      y2: "20%",
      description:
        "Transform raw data into goldmine insights with our advanced reporting and analytics tools. Visualize attendance trends, track financial performance, and identify growth opportunities at a glance. It's like having a team of expert analysts working round the clock, helping you make waves with smart, data-driven decisions. Steer your swim school towards success with crystal-clear insights guiding every move.",
    },
  ];
  const learnMoreData: LearnMoreData[] = [
    {
      id: 1,
      title: "Your All-in-One Staff Portal",
      header:
        "Dive into effortless swim school management and elevate your operations to new heights. Swim Sync’s all-in-one Command Central offers essential features that every swim school needs:",
      dataList: [
        {
          key: "Personalized Student Progress Tracking: ",
          value:
            "Monitor swimmer growth with tailored plans that adapt to their unique skills—watch them thrive!",
        },
        {
          key: "Effortless Multi-Location Management: ",
          value:
            "Oversee multiple locations from one dashboard, ensuring smooth operations across your swim school network.",
        },
        {
          key: "Instant Insights with Real-Time Reporting: ",
          value:
            "Generate actionable reports to make data-driven decisions and showcase achievements to parents.",
        },
        {
          key: "User-Friendly Interface: ",
          value:
            "Enjoy a seamless onboarding experience with an intuitive interface that gets your team up to speed in no time!",
        },
      ],
      footer:
        "Empower your staff to focus on what matters most—delivering exceptional swimming lessons and creating a safe, fun environment for all students.",
    },
    {
      id: 2,
      title: "Enrollment Made Splash-tastic",
      header:
        "Transform your swim school’s enrollment process into a smooth, flowing experience with Swim Sync's innovative features designed to boost efficiency and enhance every swimmer's journey:",
      dataList: [
        {
          key: "Streamlined Online Registration: ",
          value:
            "Simplify sign-ups with our easy-to-navigate form that saves you time and hassle.",
        },
        {
          key: "Hassle-Free Automated Payments: ",
          value:
            "Securely set up recurring payments, giving parents peace of mind while you focus on teaching.",
        },
        {
          key: "Instant Class Placement: ",
          value:
            "Quickly assign students to classes based on skill level and availability for a smooth transition to their first lesson.",
        },
        {
          key: "Comprehensive Communication Tools:",
          value:
            "Keep parents informed with automated updates on class schedules, payments, and important announcements.",
        },
      ],
      footer:
        "With Swim Sync, make enrollment a breeze and focus on nurturing your young swimmers.",
    },
    {
      id: 3,
      title: "A Portal That Makes Waves",
      header:
        "Transform the parent experience and strengthen school-parent connections with features that make engagement effortless:",
      dataList: [
        {
          key: "Seamless Enrollment Management: ",
          value:
            "Parents can easily enroll their kids, adjust schedules, and access payment histories anytime, anywhere.",
        },
        {
          key: "Real-Time Progress Tracking:",
          value:
            "Keep parents informed with visual reports showcasing their child's skills and areas for improvement.",
        },
        {
          key: "Direct Instructor Communication: ",
          value:
            "Enhance engagement through instant messaging, allowing parents to ask questions and receive timely feedback from instructors.",
        },
        {
          key: "Automated Event Notifications: ",
          value:
            "Ensure parents never miss important updates with automatic reminders for classes, events, and key announcements.",
        },
      ],
      footer:
        "With the Swim Sync Member Portal, parents actively participate in their child's swim journey, fostering a supportive community that nurtures growth both in and out of the pool.",
    },
    {
      id: 4,
      title: "Analytics That Make a Splash",
      header:
        "Navigate your swim school’s success with data-driven insights that propel you forward:",
      dataList: [
        {
          key: "Attendance Trends:",
          value:
            "Ride the waves of attendance data to identify patterns and boost student retention, keeping engagement afloat.",
        },
        {
          key: "Financial Insights:",
          value:
            "Dive into detailed financial reports that reveal revenue sources and expenses, helping you manage your budget like a pro.",
        },
      ],
      footer:
        "With Swim Sync’s powerful analytics, steer your swim school towards success and ride the waves of achievement with confidence!",
    },
  ];

  const handleOpenLearnMore = (id: number) => {
    setItemSelected(learnMoreData[id]);
    setOpenModal(true);
  };

  return (
    <div className="key-features">
      <AppModal open={openModal} onClose={() => setOpenModal(false)}>
        <div className="header-modal">
          <div className="header-modal__title">{itemSelected?.title}</div>
          <div
            className="header-modal__close"
            onClick={() => setOpenModal(false)}
          >
            <HiXMark size={20} />
          </div>
        </div>
        <div className="content-modal">
          <div className="content-modal__header">{itemSelected?.header}</div>
          <div className="content-modal__list">
            <ul className="content-modal__list__item">
              {itemSelected?.dataList?.map((item, key) => (
                <li>
                  <strong className="content-modal__list__item__key">
                    {item?.key}
                  </strong>
                  <span className="content-modal__list__item__value">
                    {item?.value}
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <div className="content-modal__footer">{itemSelected?.footer}</div>
        </div>
      </AppModal>
      <div className="dashed-line-bg">
        <div className="dashed-line"></div>
        <div className="dashed-line"></div>
        <div className="dashed-line"></div>
        <div className="dashed-line"></div>
      </div>
      <div className="key-features__header">
        <div className="key-features__header__title">Key Features Overview</div>
        <div className="key-features__header__description">
          Dive into Excellence with Swim Sync's Core Capabilities
        </div>
      </div>
      {keyFeatures.map((item, key) => (
        <div className="key-features__item" key={key}>
          <div className="key-features__item__content">
            <div>
              <div className="key-features__item__content__title">
                {item?.title}
              </div>
              <div className="key-features__item__content__subTitle">
                {item?.subTitle}
              </div>
            </div>
            <div className="key-features__item__content__description">
              {item?.description}
            </div>
            <button
              className="key-features__item__content__learnMore"
              onClick={() => handleOpenLearnMore(item?.id - 1)}
            >
              Learn More
            </button>
          </div>
          <SlideDownOnScroll threshold={0.4}>
            <div className="key-features__item__image">
              <img src={item?.image} alt={item?.title} />
            </div>
          </SlideDownOnScroll>
          <div className="key-features__item--background">
            <BackgroundAnimation
              bgColor1={item?.bgColor1}
              bgColor2={item?.bgColor2}
              bgWidth1={item?.bgWidth1}
              bgWidth2={item?.bgWidth2}
              x1={item.x1}
              x2={item.x2}
              y1={item.y1}
              y2={item.y2}
            />
          </div>
        </div>
      ))}
    </div>
  );
};
export default KeyFeatures;
