import "./desktop.scss";

import AppTooltip from "components/AppToolTip";
import { memo, useCallback, useMemo, useState } from "react";
import { HiOutlineExclamationCircle } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";

interface Props {
  isToggle?: boolean;
  onToggle?: () => void;
}

const BASE_PRICE = 299;
const TOTAL_SETUP_FEE = 899;

const Calculator = ({ isToggle, onToggle }: Props) => {
  const navigate = useNavigate();

  const [totalLocation, setTotalLocation] = useState(1);

  const handleChangeInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value.replace(/[^0-9]/g, "");

      // Check if the new value exceeds 3 digits
      if (newValue.length <= 3) {
        setTotalLocation(newValue ? Number(newValue) : 0);
      }
    },
    []
  );

  const billedMonthly = useMemo(() => {
    // yearly discounts to 85
    const newVal = BASE_PRICE * totalLocation * (isToggle ? 0.85 * 12 : 1);

    const roundedValue = Number(
      (Math.round(Number(newVal) * 100) / 100).toFixed(2)
    );

    return roundedValue;
  }, [totalLocation, isToggle]);

  const recurringCost = useMemo(() => {
    // yearly discounts to 85
    const newVal = BASE_PRICE * totalLocation * (isToggle ? 0.85 * 12 : 1);

    const roundedValue = Number(
      (Math.round(Number(newVal) * 100) / 100).toFixed(2)
    );

    return roundedValue;
  }, [totalLocation, isToggle]);

  const totalAmount = useMemo(() => {
    const newVal = recurringCost + TOTAL_SETUP_FEE;

    const roundedValue = Number(
      (Math.round(Number(newVal) * 100) / 100).toFixed(2)
    );

    return roundedValue;
  }, [recurringCost]);

  return (
    <div className="calculatorAllFeatures">
      <div className="calculatorHeader">
        <span>Pricing Calculator</span>
      </div>

      <div className="calculatorList">
        <div className="input">
          <div className="label">1. Enter Number of Locations</div>
          <input
            type="text"
            value={totalLocation}
            onChange={handleChangeInput}
          />
          <div className="label2">2. Select Billing Cycle</div>
          <div className="btnList">
            <button
              className={`btn ${!isToggle ? "active" : ""}`}
              onClick={onToggle}>
              Monthly
            </button>
            <button
              className={`btn ${isToggle ? "active" : ""}`}
              onClick={onToggle}>
              Yearly
            </button>
          </div>
        </div>
        <div className="output">
          <div className="name">Billed {isToggle ? "Yearly" : "Monthly"}</div>
          <div className="price">
            ${billedMonthly}
            <span className="subPrice">/{isToggle ? "yr" : "mo"}</span>
          </div>

          <div className="priceGrid">
            <div className="col1">
              Recurring costs{" "}
              <AppTooltip
                tooltipText={
                  <div className="tt_text">
                    Our comprehensive setup process includes:
                    <ul>
                      <li>Software installation and configuration</li>
                      <li>Custom branding setup Integration with your </li>
                      <li>Integration with your existing tools</li>
                      <li>Initial staff training session</li>
                      <li>Personalized onboarding support</li>
                    </ul>
                  </div>
                }
                position="top"
                typeToolTipText="max">
                <HiOutlineExclamationCircle size={20} />
              </AppTooltip>
            </div>
            <div className="col2">${recurringCost}</div>

            <div className="col1">
              Total setup fees{" "}
              <AppTooltip
                tooltipText={
                  <div className="tt_text">
                    Our comprehensive setup process includes:
                    <ul>
                      <li>Software installation and configuration</li>
                      <li>Custom branding setup Integration with your </li>
                      <li>Integration with your existing tools</li>
                      <li>Initial staff training session</li>
                      <li>Personalized onboarding support</li>
                    </ul>
                  </div>
                }
                position="top"
                typeToolTipText="max">
                <HiOutlineExclamationCircle size={20} />
              </AppTooltip>
            </div>
            <div className="col2">${TOTAL_SETUP_FEE}</div>

            <div className="col1">Total Amount</div>
            <div className="col2">${totalAmount}</div>
          </div>

          <button
            className="btnGetStarted"
            onClick={() => navigate("/interested")}>
            Get Started
          </button>
        </div>
      </div>
    </div>
  );
};

export default memo(Calculator);
