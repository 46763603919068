import Slider from "react-slick";
import SlideUpOnScroll from "components/SlideUpOnScroll";
import "./desktop.scss";

const Introduction = () => {
  const settings = {
    centerMode: true,
    infinite: true,
    centerPadding: "10px",
    slidesToShow: 2.65,
    speed: 500,
    dots: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  const imageData: string[] = [
    "/images/introductionMP/image5.png",
    "/images/introductionMP/image6.png",
    "/images/introductionMP/image7.png",
    "/images/introductionMP/image8.png",
  ];

  const listFeatures = [
    {
      id: 1,
      title: "User Management",
      description:
        "Efficiently manage staff, instructors, and member accounts.",
      icon: "/svgs/introduction/clipboard.svg",
      subTitle: "Comprehensive User Management",
      subDescription:
        "Efficiently manage all user accounts within your swim school ecosystem:",
      subList: [
        "Create and manage staff, instructor, and member accounts",
        "Set user roles and permissions with granular access control",
        "Track instructor certifications and schedule training reminders",
        "Manage parent/guardian relationships with student accounts",
      ],
    },
    {
      id: 2,
      title: "Program & Class Setup",
      description: "Create and customize swim programs and class structures.",
      icon: "/svgs/introduction/calendar-swim.svg",
      subTitle: "Flexible Program & Class Set up",
      subDescription:
        "Tailor your swim programs to meet your school's unique needs:",
      subList: [
        "Create custom swim programs and define skill levels",
        "Set up class types, sizes, and age groups",
        "Define prerequisite skills and auto-progression rules",
        "Customize lesson plans and skill checklists for each level",
        "Manage special programs like private lessons or events",
      ],
    },
    {
      id: 3,
      title: "Scheduling System",
      description: "Optimize class schedules across multiple locations.",
      icon: "/svgs/introduction/chart-swim.svg",
      subTitle: "Advanced Scheduling System",
      subDescription:
        "Optimize your swim school's operations across all locations:",
      subList: [
        "Manage schedules for multiple pools and locations",
        "Automatically assign instructors based on qualifications and availability",
        "Handle recurring classes, make-up sessions, and special events",
        "Implement conflict resolution for overlapping schedules",
        "Visualize pool utilization and identify optimization opportunities",
      ],
    },
    {
      id: 4,
      title: "Attendance Tracking",
      description: "Monitor and manage attendance for all classes and events.",
      icon: "/svgs/introduction/messages.svg",
      subTitle: "Robust Attendance Tracking",
      subDescription: "Keep detailed records of student participation:",
      subList: [
        "Mark attendance for classes, events, and private lessons",
        "Enable mobile attendance tracking for instructors",
        "Generate attendance reports and identify trends",
        "Set up automated notifications for absent students",
        "Track make-up classes and rescheduled sessions",
      ],
    },
    {
      id: 5,
      title: "Progress Reporting",
      description: "Generate and analyze student progress reports.",
      icon: "/svgs/introduction/shield-dollar.svg",
      subTitle: "Comprehensive Progress Reporting",
      subDescription:
        "Monitor and communicate student achievements effectively:",
      subList: [
        "Create customizable skill assessment templates",
        "Enable instructors to input progress notes and skill ratings",
        "Generate individual and group progress reports",
        "Visualize skill progression over time with interactive charts",
        "Automate level advancement recommendations based on progress",
      ],
    },
    {
      id: 6,
      title: "Financial Management",
      description: "Handle billing, payments, and financial reporting.",
      icon: "/svgs/introduction/block-waves.svg",
      subTitle: "Integrated Financial Management",
      subDescription: "Streamline your swim school's financial operations:",
      subList: [
        "Process payments and manage billing cycles",
        "Handle multiple payment methods and recurring payments",
        "Generate financial reports and forecasts",
        "Manage discounts, promotions, and gift certificates",
      ],
    },
    {
      id: 8,
      title: "Communication Tools",
      description: "Send notifications and manage school-wide communications.",
      icon: "/svgs/introduction/block-waves.svg",
      subTitle: "Powerful Communication Tools",
      subDescription:
        "Keep your entire swim school community informed and engaged:",
      subList: [
        "Send targeted notifications to specific groups or individuals",
        "Create and manage email campaigns for marketing and updates",
        "Enable two-way communication between staff and members",
        "Schedule automated reminders for classes, events, and payments",
        "Manage emergency alerts for weather-related closures or changes",
      ],
    },
    {
      id: 9,
      title: "Analytics Dashboard",
      description: "Access real-time data and insights on school performance.",
      icon: "/svgs/introduction/shield-dollar.svg",
      subTitle: "Insightful Analytics Dashboard",
      subDescription:
        "Gain valuable insights to drive your swim school's success:",
      subList: [
        "Access real-time data on enrollment, attendance, and financial performance",
        "Visualize key performance indicators with customizable charts and graphs",
        "Track instructor performance and student satisfaction ratings",
        "Identify trends and patterns to inform business decisions",
        "Generate comprehensive reports for stakeholders and regulatory compliance",
      ],
    },
  ];

  return (
    <>
      <div className="AdministrativeTools-introduction">
        <SlideUpOnScroll>
          <div className="AdministrativeTools-introduction--header">
            <div className="AdministrativeTools-introduction--header--description">
              Empower your swim school management with Swim Sync's comprehensive
              Administrative Tools. Designed to streamline operations, enhance
              efficiency, and provide valuable insights, our admin suite gives
              you complete control over your swim school's day-to-day operations
              and long-term strategy.
            </div>
          </div>
        </SlideUpOnScroll>
        <SlideUpOnScroll>
          <div className="AdministrativeTools-introduction--slider-wrapper">
            <Slider {...settings}>
              {imageData.map((item, key) => (
                <div
                  key={key}
                  className="AdministrativeTools-introduction--slider-wrapper--image"
                >
                  <img src={item} alt="" />
                </div>
              ))}
            </Slider>
          </div>
        </SlideUpOnScroll>
      </div>
      <div className="AdministrativeTools-introduction--featuresContainer">
        <div className="AdministrativeTools-introduction--featuresWrapper">
          {listFeatures.map((item, key) => (
            <div
              key={key}
              className="AdministrativeTools-introduction--featuresItem"
            >
              <div className="AdministrativeTools-introduction--featuresItemMain">
                <div className="AdministrativeTools-introduction--featuresItemLeft">
                  <img
                    src={item.icon}
                    alt=""
                    className="AdministrativeTools-introduction--featuresItemLeft--icon"
                  />
                  <div className="AdministrativeTools-introduction--featuresItemLeft--title">
                    {item.title}
                  </div>
                  <div className="AdministrativeTools-introduction--featuresItemLeft--description">
                    {item.description}
                  </div>
                </div>
                <div className="AdministrativeTools-introduction--featuresItemRight">
                  <p className="AdministrativeTools-introduction--featuresItemRight--subTitle">
                    {item.subTitle}
                  </p>
                  <p className="AdministrativeTools-introduction--featuresItemRight--subDescription">
                    {item.subDescription}
                  </p>
                  <ul>
                    {item.subList.map((li, liIndex) => {
                      return <li key={liIndex}>{li}</li>;
                    })}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default Introduction;
