import "./desktop.scss";

import React, { memo } from "react";

interface AppCheckboxProps {
  label: string;
  checked: boolean;
  required?: boolean;
  onChange: (isChecked: boolean) => void;
}

const AppCheckbox: React.FC<AppCheckboxProps> = ({
  label,
  checked,
  required,
  onChange,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked); // Pass the checked state to the parent
  };

  return (
    <div className="c__appCheckbox">
      <label>
        <input
          type="checkbox"
          checked={checked}
          aria-required={required}
          aria-checked={checked}
          onChange={handleChange}
        />
        <div className="label"></div>
        <div>{label}</div>
        {required ? <span className="requireTag">*</span> : null}
      </label>
    </div>
  );
};

export default memo(AppCheckbox);
