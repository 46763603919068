import "./desktop.scss";

import React, { memo } from "react";

interface AppInputProps {
  name: string;
  label?: string;
  type?: string;
  animatedLabel?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  placeholder?: string; // Added placeholder prop
}

const AppInput: React.FC<AppInputProps> = ({
  name,
  label,
  type = "text",
  onChange,
  required,
  animatedLabel = true,
  placeholder,
}) => {
  return (
    <div className="c__appInput">
      <input
        id={name}
        name={name}
        type={type}
        required={animatedLabel}
        onChange={onChange}
        placeholder={placeholder}
      />

      {label ? (
        <label htmlFor={name}>
          {label} {required ? <span className="requireTag">*</span> : null}
        </label>
      ) : null}
    </div>
  );
};

export default memo(AppInput);
